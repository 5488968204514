<template>
    <div class="epg-channel-wrapper" >
        <!--previous-arrow-->
        <!-- <div class="w-100 d-none" v-if="channelData.length > 6">
            <button type="button" class="up-down-arrow up-arrow" @click="actUpDownArrow('up')">
                <img src="@/assets/icons/less-than-filled-50.png" width="30px" alt="less" />
            </button>
        </div> -->
        <div class="cast-user-container slider-container" >
            <ul class="user-list slider-content">
                <li class="user-item hand" :class="[selectedChannelIndex == i ? 'active' : '', item.catchupperiod ? 'catchupperiod' : '']" v-for="(item, i) in channelsData" :key="i" @click="actSelectChannel(item, i)" :title="item['display-name']">
                    <Intersect 
                        @enter="onUserItemEnter"
                        @leave="onUserItemLeave"
                        :root-margin="'0px 0px -50px 0px'"
                        :threshold="[0.5]"
                    >
                        <div class="user-img">
                            <img  :src="placeholder_lands" :data-src="getImageSrc(item.icon.src)"  width="68" class="cast-image" :alt="item['display-name']" @error="actLoadError" data-lazy-load/>
                        </div>
                    </Intersect>
                    <div class="cast-name">
                        <div class="channel-no-block">
                            <div class="clamped-text" v-html="item['display-name']"></div>
                            <div class="channel-no">
                               CH NO : {{item['id']}}
                            </div>
                        </div>
                        <div class="channel-catchupperiod" v-if="item.catchupperiod">CU TV</div>
                    </div>
                </li>
                <li class="user-item hand" key="placeholder" v-if="showPaginationLoader">
                    <Intersect 
                        @enter="onEnter"
                        @leave="onLeave"
                        :root-margin="'0px 0px -50px 0px'"
                        :threshold="[0.5]"
                    >
                        <div class="loader">
                            Loading...
                        </div>
                    </Intersect>
                </li>
                
            </ul>
        </div>
        <!--next-arrow-->
        <!-- <div class="w-100 d-none" v-if="channelData.length > 6">
            <button type="button" class="up-down-arrow down-arrow" @click="actUpDownArrow('Down')">
                <img src="@/assets/icons/less-than-filled-50.png" width="30px" alt="less" />
            </button>
        </div> -->
    </div>
</template>
<script>
    import { observerInterSection } from "@/modules/observerInterSection";

   import { mapGetters } from "vuex";
    import Intersect from "vue-intersect";

    export default {
       name: 'ChannelContainer',
        props: ['channelsData', 'actSelectedChannel', 'actPagination', 'showPaginationLoader'],
        computed: {
            ...mapGetters([ "appConfig" ]),

            catchUPText() {
            const displayText = this.appConfig?.displayText;
            return (
                displayText?.[this.localDisplayLang]?.CatchUPText ||
                displayText?.default?.CatchUPText || 'CU TV'
            );
            },
        },
        data() {
            return {
                selectedChannelIndex: 0,
                placeholder_lands: require('@/assets/placeholder-lands.svg'),
            }
        },
        watch: {
            channelsData(newVal, oldVal) {
                observerInterSection();
            }
            
        },
        mounted() {
            observerInterSection();
        },
        methods: {
            actSelectChannel(channel, i) {
                this.selectedChannelIndex = i;
                this.$emit('actSelectedChannel', {channel, i});
            },        
            getImageSrc(value) {
                let src = value.replace(/&amp;/g, "&");
                return src ? `${src}` : this.placeholder_lands;
            },
            actLoadError (e) {
                e.target.src = this.placeholder_lands;
            },
            onEnter() {
                // observerInterSection();
                this.$emit('actPagination');
                // this.$emit('actPagination');
                // Handle logic when the element becomes visible.
            },
            onUserItemEnter() {
                observerInterSection();
            },
            onLeave() {
                // console.log('Element left the viewport!');
                // Handle logic when the element is no longer visible.
            },
            onUserItemLeave() {

            }
        },
        components: {
            Intersect,
        }
    }
</script>
<style scoped lang="scss">
    @import "@/sass/_variables.scss";
    @import "@/sass/_components.scss";

    .epg-channel-wrapper {
        height: 100%;
        position: relative;
        .up-down-arrow {
            width: 100%;
            border: none;
            background: transparent;
            cursor: pointer;
            text-align: center;
            z-index: 9;
        }
        .up-arrow {
            position: absolute;
            top: 0;
            img {                        
                transform: rotate(90deg);
            }
        }
        .down-arrow {
            img{
                transform: rotate(270deg);
            }
            position: absolute;
            bottom: 0;
        }
        .cast-user-container {
            height: 100%;
            overflow: auto;
            .user-list {
                padding: 0.5rem;
                .user-item {
                    display: flex;
                    align-items: center;
                    gap: 0.2rem;
                    height: 3.5rem;
                    padding: 0.5rem;
                    color: $clr-text;
                    border-radius: 3px;
                    background: #817c7c26;
                    cursor: pointer;
                    .user-img{
                    display: flex;
                        align-items: center;
                        justify-content: center;
                        .cast-image{
                                // border-radius: 50%;
                                border-radius: 4px;
                                border: 2px solid #77777736;
                                background: #000000;
                                aspect-ratio: 16/12;
                        }
                    }
                    &:hover {
                        background-color: #707070;
                        color: #fff !important;
                        // .channel-catchupperiod, .channel-no {
                        //     border: #FFFFFF 0.1rem solid !important;
                        // }
                    }

                    .loader {
                        text-align: center;
                        width: -webkit-fill-available;
                    }
                    .cast-name {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        .channel-no-block {
                            display: flex;
                            flex-direction: column;
                            gap: 0.5rem;
                            width: 72%;
                            .clamped-text {
                                font-size: 0.9rem;
                                display: -webkit-box;       /* Use WebKit-based box model */
                                -webkit-line-clamp: 1;      /* Limit to 2 lines */
                                -webkit-box-orient: vertical; /* Set orientation to vertical */
                                overflow: hidden;           /* Hide overflowed text */
                                text-overflow: ellipsis;    /* Show ellipsis for overflow */
                                max-height: calc(2 * 1.2em); /* Adjust max-height (line-height * number of lines) */
                                line-height: 1.2em;         /* Set line height for consistent spacing */
                            }
                            .channel-no {
                                // border: #707070 solid 0.1rem;
                                padding: 0.1rem;
                                border-radius: 0.2rem;
                                font-size: 0.7rem;
                                width: fit-content;
                                height: fit-content;
                            }
                        }
                        .channel-catchupperiod {
                            // border: #707070 solid 0.1rem;
                            padding: 0.2rem;
                            border-radius: 0.2rem;
                            font-size: 0.6rem;
                            width: fit-content;
                            height: fit-content;
                            background: red;
                        }
                    }
                }
                // .catchupperiod {
                //     border: 0.1rem #70787f solid;
                // }
            }
        }

        .slider-container {
            position: relative;
            overflow: auto;
        }

        .slider-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 101%;
            display: flex;
            flex-direction: column;
            transition: transform 0.3s ease;
            overflow: auto;
            gap:10px;
        }
    }    
    .active { 
        background: $primary-btn-color !important;
        color: #FFFFFF !important;
        // .channel-no, .channel-catchupperiod {
        //     border: #f3f3f3 solid 0.1rem !important;
        // }
        .channel-catchupperiod {
            background: red;
        }
    }
    .user-item:hover:not(.active) {
        background-color: black;
    }
    @media only screen and (max-width: 768px) {
        .epg-channel-wrapper {
            .cast-user-container {
                height: 100%;
                overflow: auto;
                .user-list {
                    .user-item {
                        flex-direction: column;
                        gap: 0.2rem !important;
                        font-size:0.7rem;
                        height: auto !important;
                        padding: 0.25rem;
                        .user-img {
                            .cast-image {
                                width: 100%;
                            }
                        }
                        .cast-name {
                            width: 99%;
                            word-break: break-all;
                            flex-direction: column;
                        .channel-no-block {
                            display: flex;
                            flex-direction: column;
                            gap: 0.25srem;
                            width: 100%;
                            .clamped-text {
                                font-size: 0.8rem;     /* Set line height for consistent spacing */
                            }
                            .channel-no {
                                font-size: 0.35rem;
                            }
                        }
                        .channel-catchupperiod {
                            font-size: 0.3rem;
                            text-align: center;
                        }
                        }
                    }
                }
            }
        }
}
</style>